import { Injectable, computed, signal } from '@angular/core';
import { ModuleMeta } from './interface/interface-list';
import { LoginComponent } from './landing/login/login.component';
import { SubProductComponent } from './product/sub-product/sub-product.component';
import { Route } from '@angular/router';
import { DataSharingService } from './product/dataSharing/data-sharing.service';
import { UserPrivilages } from './role-management/role-management/role-management/user-privilages';

@Injectable({
  providedIn: 'root',

})
export class PermissionService {

  uiComponentPrivileges: any[] = [
    
  ];
  res: any;
  userPrevilages: UserPrivilages[] = [];

  constructor(private data: DataSharingService) {
    this.data.setOperator$.subscribe((res) => {
      let loginUserName = sessionStorage.getItem('loginUserName');
      this.res = res;
      if (res) {
        this.uiComponentPrivileges.push(res);
        sessionStorage.setItem("privileges",JSON.stringify(this.uiComponentPrivileges))
      }
    });
    this.data.emptyArray$.subscribe((res:any)=>{
      if(res === true){
        this.uiComponentPrivileges = []
      }
    })
    
  }

  getUserPrevilages() {
    if (sessionStorage.getItem('privileges')) {
      // this.setSidebarItems();
    }
    return this.userPrevilages;
  }

  

  modules: ModuleMeta[] = [
    {
      name: 'Dashboard',
      location: './dashboard/',
      icon: 'assets/icons/dashboard.png',
      components: [
        { name: "CustomerComponent", location: "customer.component", displayLink: "Back office" },
        // { name: "AgentComponent", location: "agent/agent.Component", displayLink: "Agents" }
      ]
    },
    {
      name: 'Agency Fees And Commission',
      location: './kyc',
      icon: 'assets/icons/key.png',
      components: [
        {name:'AgencyFeesAndCommission',location:'home',displayLink:'Agency Fees And Commission'},
      ]
    },
    {
      name: 'AML',
      location: './aml',
      icon: 'assets/icons/key.png',
      components: [
        { name: "SDNupload", location: "home", displayLink: "SDN Upload" },
        { name: "list-risk-parameter", location: "home", displayLink: "Risk Parameter" },
        { name: "list-risk-tier", location: "home", displayLink: "Risk Tier" },
      ]
    },
    {
      name: 'KYC',
      location: './kyc',
      icon: 'assets/icons/key.png',
      components: [
        { name: "kyc/customer", location: "home", displayLink: "All KYC" },
        { name: "kyc/agent", location: "home", displayLink: "Pending KYC" },
      ]
    },
    {
      name: 'User Management',
      location: './UserManagement',
      icon: 'assets/icons/key.png',
      components: [
        { name: "BOLanding", location: "user-modal/user-modal.component", displayLink: "Back Office User" },
        { name: "corporate/corporate-list", location: "user-modal/user-modal.component", displayLink: "Corporate Onboarding" },
         { name: "CustomerLanding", location: "user-modal/user-modal.component", displayLink: "Customer" },
        // { name: "AgentLanding", location: "user-modal/user-modal.component", displayLink: "Super Agent" },
        // { name: "SubAgentLanding", location: "user-modal/user-modal.component", displayLink: "Agent" },
        // { name: "MerchantLanding", location: "merchant/merchant.component", displayLink: "Merchant" },

        { name: "corporate/list-master-agent", location: "user-modal/user-modal.component", displayLink: "Master Agent" },
        { name: "corporate/list-direct-agent", location: "user-modal/user-modal.component", displayLink: "Direct Agent" },
        { name: "MerchantLanding", location: "merchant/merchant.component", displayLink: "Agent" },
        { name: "viewcorporate", location: "merchant/merchant.component", displayLink: "Corporate User Registration" },

        { name: "kyc/customer", location: "home", displayLink: "Customer Kyc" },
        { name: "kyc/agent", location: "home", displayLink: "Agents Kyc" },
              // { name: "CorporateLanding", location: "user-modal/user-modal.component", displayLink: "Corporate" },
      ]
    },
    {
      name: "Role Management",
      location: "./RoleManagement",
      icon: "assets/icons/key.png",
      components: [
        {
          name: "ComponentList",
          location: "cashback/cashback.component",
          displayLink: "Component",
        },
        {
          name: "SubComponentList",
          location: "cashback/cashback.component",
          displayLink: "Sub Component",
        },
        {
          name: "Branch",
          location: "cashback/cashback.component",
          displayLink: "Branch",
        },
        {
          name: "Department",
          location: "cashback/cashback.component",
          displayLink: "Department",
        },
        {
          name: "Role",
          location: "cashback/cashback.component",
          displayLink: "Role",
        },
        // { name: "Permission", location: "cashback/cashback.component", displayLink: "Permission" },
      ],
    },
    // {
    //   name: "Supplier Management",
    //   location: "./SupplierManagement",
    //   icon: "assets/icons/key.png",
    //   components: [
    //     {
    //       name: "SupplierMaster",
    //       location: "cashback/cashback.component",
    //       displayLink: "Supplier Master",
    //     },
    //     {
    //       name: "TopupCreditPurchase",
    //       location: "cashback/cashback.component",
    //       displayLink: "Topup Credit Purchase",
    //     },
        // {
        //   name: "Branch",
        //   location: "cashback/cashback.component",
        //   displayLink: "Branch",
        // },
        // {
        //   name: "Department",
        //   location: "cashback/cashback.component",
        //   displayLink: "Department",
        // },
        // {
        //   name: "Role",
        //   location: "cashback/cashback.component",
        //   displayLink: "Role",
        // },
        // { name: "Permission", location: "cashback/cashback.component", displayLink: "Permission" },
    //   ],
    // },

    {
      name: 'Product Management',
      location: './service-management',
      icon: 'assets/icons/verify.png',
      components: [
        { name: "ProductComponent", location: "product/product.component", displayLink: "Product" },
        { name: "FeesAndCommissionSlabComponent", location: "fees-and-commission-slab/fees-and-commission-slab.component", displayLink: "Agency Fees and Commission" },
        // { name: "createProduct", location: "home", displayLink: "Product Master" },
        // { name: "s2", location: "home", displayLink: "Agents" }
     
      ]
    },
    {
      name: 'Profile Management',
      location: './profile-management',
      icon: 'assets/icons/key.png',
      components: [
        { name: "p1", location: "home", displayLink: "Agents" },
        { name: "p2", location: "home", displayLink: "Agents" }
      ]
    },
    {
      name: 'Transaction and GL',
      location: './profile-management',
      icon: 'assets/icons/key.png',
      components: [
        // { name: "account-maintanance", location: "home", displayLink: "Account Maintanance" },
        { name: "transaction-and-gl/list-transaction-and-gl", location: "home", displayLink: "Account Maintanance" },
        { name: "transaction-and-gl/transaction-monitoring", location: "home", displayLink: "Transaction Monitoring" },
        { name: "transaction-and-gl/refund-transaction", location: "home", displayLink: "Refund Transaction" },
        { name: "transaction-and-gl/chart-of-accounts", location: "home", displayLink: "Chart of Accounts" }
        
      ]
    },
    {
      name: 'Bulk Upload',
      location: './profile-management',
      icon: 'assets/icons/key.png',
      components: [
        // { name: "account-maintanance", location: "home", displayLink: "Account Maintanance" },
        { name: "bulkUpload", location: "home", displayLink: "Bulk Salary Upload" },
        { name: "bulkCustomerUpload", location: "home", displayLink: "Bulk Customer Onboarding" },

      ]
    },
    {
      name: 'Bulk Salary Process',
      location: './profile-management',
      icon: 'assets/icons/key.png',
      components: [
        { name: "salaryprocess", location: "home", displayLink: "Bulk Salary Process" },
        // { name: "bulkCustomerUpload", location: "home", displayLink: "Bulk Customer Onboarding" },

      ]
    },
    {
      name: 'Payment and Money Transfer',
      location: './product',
      icon: 'assets/icons/verify.png', // Assuming you have an icon for product
      components: [
        { name: "ServiceComponent", location: "service/service.component", displayLink: "Service" },
        // { name: "ProductmasterComponent", location: "cashback/cashback.component", displayLink: "Product Master" },
/*         { name: "ServiceComponent", location: "service/service.component", displayLink: "Service" },
 */     { name: "AmountLimitComponent", location: "amount-limit/amount-limit.component", displayLink: "Amount Limit" },
        { name: "TransactionLimitComponent", location: "transaction-limit/transaction-limit.component", displayLink: "Transaction Limit" },
        { name: "ChargesAndCommissionComponent", location: "charges-and-commission/charges-and-commission.component", displayLink: "Charges and Commission" },
        { name: "FeesAndCommissionComponent", location: "fees-and-commission/fees-and-commission.component", displayLink: "Fees and Commission" },
        // { name: "FeesAndCommissionSlabComponent", location: "fees-and-commission-slab/fees-and-commission-slab.component", displayLink: "Agency Fees and Commission" },
        { name: "TaxComponent", location: "tax/tax.component", displayLink: "Tax" },
        { name: "CashbackComponent", location: "cashback/cashback.component", displayLink: "Cashback" },
/*         { name: "SubProductComponent", location: "sub-product/sub-product.component", displayLink: "SubProduct" },
 */
  // { name: "ProductComponent", location: "product/product.component", displayLink: "SubProduct" },
 { name: "SubProductComponent", location: "sub-product/sub-product.component", displayLink: "SubProduct PT" },


      ]
    },
    {
      name: 'Funding and Withdrawal',
      location: './product',
      icon: 'assets/icons/key.png', // Assuming you have an icon for product
      components: [
        { name: "ServiceComponent", location: "service/service.component", displayLink: "Service" },
        { name: "SubProductComponent", location: "sub-product/sub-product.component", displayLink: "SubProduct" },
        { name: "TransactionLimitComponent", location: "transaction-limit/transaction-limit.component", displayLink: "Transaction Limit" },
        { name: "ChargesAndCommissionComponent", location: "charges-and-commission/charges-and-commission.component", displayLink: "Charges and Commission" },
        // { name: "ProductComponent", location: "product/product.component", displayLink: "Product" },
        { name: "TaxComponent", location: "tax/tax.component", displayLink: "Tax" },
        { name: "FeesAndCommissionSlabComponent", location: "fees-and-commission-slab/fees-and-commission-slab.component", displayLink: "Agency fees and CommissionSlab" },
        { name: "FeesAndCommissionComponent", location: "fees-and-commission/fees-and-commission.component", displayLink: "FPW Fees and Commission" },
        { name: "AmountLimitComponent", location: "amount-limit/amount-limit.component", displayLink: "Amount Limit" },
        { name: "CashbackComponent", location: "cashback/cashback.component", displayLink: "Cashback" }

      ]
    },
     {
      name: 'Wallet Management',
      location: './WalletManagement',
      icon: 'assets/icons/key.png',
      components: [
        { name: "WalletManagement", location: "user-modal/user-modal.component", displayLink: "Wallet Accounts" },
        { name: "Systemwallets", location: "user-modal/user-modal.component", displayLink: "System Wallets" },
        { name: "pushPullMoney", location: "user-modal/user-modal.component", displayLink: "Push/Pull Money" },


      ]
    },
    {
      name: 'Supplier Management',
      location: './SupplierManagement',
      icon: 'assets/icons/key.png',
      components: [
        { name: "SupplierList", location: "user-modal/user-modal.component", displayLink: "Supplier Master" },
        { name: "CreateTopup", location: "user-modal/user-modal.component", displayLink: "Topup Credit Purchase"},
      ]
    },
    {
      name: 'Report Management',
      location: './SupplierManagement',
      icon: 'assets/icons/key.png',
      components: [
        { name: "BalanceSheet", location: "home", displayLink: "Balance Sheet" },
        { name: "BatchReport", location: "home", displayLink: "Batch Report" },
        { name: "BreshnaReconcilitationReport", location: "home", displayLink: "BreshnaReconcilitation Report" },
        { name: "TransactionSummaryReport", location: "home", displayLink: "TransactionSummary Report" },
        { name: "AgentLiquidityReport", location: "home", displayLink: "AgentLiquidity Report" },
        { name: "AgentSummaryReport", location: "home", displayLink: "AgentSummary Report" },
        { name: "CashInReport", location: "home", displayLink: "CashIn Report" },
        { name: "CashOutReport", location: "home", displayLink: "CashOut Report" },
        { name: "EMoneyTransferReport", location: "home", displayLink: "EMoneyTransfer Report" },
        { name: "LCTR", location: "home", displayLink: "LCTR Report" },
        { name: "SalesTrendReport", location: "home", displayLink: "SalesTrend Report" },
        { name: "StockBatchReport", location: "home", displayLink: "StockBatch Report" },
        { name: "ProfitabilityReport", location: "home", displayLink: "Profitability Report" },
        { name: "CorporateSummaryReport", location: "home", displayLink: "CorporateSummary Report" },
        { name: "CustomerSummaryReport", location: "home", displayLink: "CustomerSummary Report" },
        { name: "SalesVolumeByAgents", location: "home", displayLink: "SalesVolumeByAgents Report" },
        { name: "SalesVolumeByChannel", location: "home", displayLink: "SalesVolumeByChannel Report" },
        { name: "ProfitAndLossReport", location: "home", displayLink: "ProfitAndLoss Report" },
        { name: "BankReconcilitationReport", location: "home", displayLink: "BankReconcilitation Report" },
        { name: "BankReport", location: "home", displayLink: "Bank Report" },
        { name: "AgentReconcilationReport", location: "home", displayLink: "AgentReconcilation Report" },
        { name: "AccStatementReport", location: "home", displayLink: "AccStatement Report" },
        { name: "AirtimeReconciliationReport", location: "home", displayLink: "AirtimeReconciliation Report" },
        { name: "BeginningBalanceReport", location: "home", displayLink: "BeginningBalance Report" },
        { name: "BillPaymentReport", location: "home", displayLink: "BillPayment Report" },
        { name: "QuarterlyProfitAndLoss", location: "home", displayLink: "QuarterlyProfitAndLoss Report" },
        { name: "MonthlyBalanceReport", location: "home", displayLink: "MonthlyBalance Report" },
        { name: "MonthlyProfitAndLoss", location: "home", displayLink: "MonthlyProfitAndLoss Report" },
        { name: "FinancialConsolidatedReport", location: "home", displayLink: "FinancialConsolidated Report" },
        { name: "DirectBankingCashFlowReport", location: "home", displayLink: "DirectBankingCashFlow Report" },
        { name: "KYCReport", location: "home", displayLink: "KYC Report" },
      ]
    },
  ];

  permittedModels = signal<ModuleMeta[]>(this.modules)

  getPermittedModules(): ModuleMeta[] {
    // Initialize an array to store modules with permitted components
    const permittedModules: ModuleMeta[] = [];

    // Iterate through each module
    this.modules.forEach((module) => {
      // Find matching privileges for the current module
      const matchingPrivileges = this.uiComponentPrivileges.filter(
        (privilege: any) => {
          // Check if component name matches module name and has matching subComponent
          return privilege.uiComponentPrivileges.some(
            (uiComponentPrivilege: any) => {
              return (
                uiComponentPrivilege.component.componentName === module.name &&
                module.components.some((component) => {
                  return (
                    uiComponentPrivilege.subComponent.subComponentName ===
                    component.displayLink 
                  );
                })
              );
            }
          );
        }
      );

      // If matching privileges are found, filter components
      // if (matchingPrivileges.length > 0) {
      const permittedComponents = module.components.filter((component) => {
        // Check if any matching privilege contains this component's displayLink
        return matchingPrivileges.some((privilege: any) => {
          return privilege.uiComponentPrivileges.some(
            (uiComponentPrivilege: any) => {
              // console.log(
              //   uiComponentPrivilege.subComponent.subComponentName,
              //   component.displayLink
              // );
              return (
                uiComponentPrivilege.subComponent.subComponentName ===
                component.displayLink && uiComponentPrivilege.subComponent.active === true
              );
            }
          );
        });
      });
      // Add module with permitted components to the result array
      if (permittedComponents.length > 0) {
        permittedModules.push({
          ...module,
          components: permittedComponents,
        });
      }
      // }
    });
    return permittedModules;
  }

  computeRoutes(): Route[] {
    const routes: Route[] = [
      {
        path : 'web/customer/login',
        component : LoginComponent,
        pathMatch: 'full', data: { loginType: 'customer' }
      },
      {
        path : 'web/backOffice/login',
        component : LoginComponent,
        pathMatch: 'full', data: { loginType: 'admin' }
      },
      { path: '**', redirectTo: 'web', pathMatch: 'full' },
      {
        path : 'web/agent/login',
        component : LoginComponent,
        pathMatch: 'full', data: { loginType: 'agent' }
      },
      {
        path: 'CustomerComponent',
        loadComponent: () => import('./dashboard/customer/customer.component').then(c => c.CustomerComponent)
      },
      {
        path: 'AgentComponent',
        loadComponent: () => import('./dashboard/agent/agent.component').then(c => c.AgentComponent)
      },
      {
        path: 'kyc/customer',
        loadComponent: () => import('./kyc/kyc/customer-kyc/customer-kyc.component').then(c => c.CustomerKycComponent)
      },
      {
        path: 'transaction-and-gl/create-transaction-and-gl',
        loadComponent: () => import('./transaction-and-gl/create-transaction-and-gl/create-transaction-and-gl.component').then(c => c.CreateTransactionAndGlComponent)
      },
      {
        path: 'transaction-and-gl/list-transaction-and-gl',
        loadComponent: () => import('./transaction-and-gl/list-transaction-and-gl/list-transaction-and-gl.component').then(c => c.ListTransactionAndGlComponent)
      },
      {
        path: 'transaction-and-gl/refund-transaction',
        loadComponent: () => import('./transaction-and-gl/refund-transaction/refund-transaction.component').then(c => c.RefundTransactionComponent)
      },
      {
        path: 'transaction-and-gl/process-refund',
        loadComponent: () => import('./transaction-and-gl/process-refund/process-refund.component').then(c => c.ProcessRefundComponent)
      },
      
      {
        path: 'transaction-and-gl/chart-of-accounts',
        loadComponent: () => import('./transaction-and-gl/chart-of-accounts/chart-of-accounts.component').then(c => c.ChartOfAccountsComponent)
      },
       {
        path: 'transaction-and-gl/transaction-monitoring',
        loadComponent: () => import('./transaction-and-gl/transaction-monitoring/transaction-monitoring.component').then(c => c.TransactionMonitoringComponent)
      },
      {
        path: 'corporate/corporate-registartion',
        loadComponent: () => import('./corporate/corporate-registartion/corporate-registartion.component').then(c => c.CorporateRegistartionComponent)
      },
      {
        path: 'corporate/corporate-list',
        loadComponent: () => import('./corporate/corporate-list/corporate-list.component').then(c => c.CorporateListComponent)
      },
      //new

      {
        path: 'corporate/list-master-agent',
        loadComponent: () => import('./corporate/list-master-agent/list-master-agent.component').then(c => c.ListMasterAgentComponent)
      },
      {
        path: 'corporate/create-master-agent',
        loadComponent: () => import('./corporate/create-master-agent/create-master-agent.component').then(c => c.CreateMasterAgentComponent)
      },
      {
        path: 'corporate/list-direct-agent',
        loadComponent: () => import('./corporate/list-direct-agent/list-direct-agent.component').then(c => c.ListDirectAgentComponent)
      },
      {
        path: 'corporate/create-direct-agent',
        loadComponent: () => import('./corporate/create-direct-agent/create-direct-agent.component').then(c => c.CreateDirectAgentComponent)
      },
      
      {
        path: 'corporate/create-agent',
        loadComponent: () => import('./corporate/create-agent/create-agent.component').then(c => c.CreateAgentComponent)
      },
      //new
      {
        path: 'kyc/view-superagent-kyc/:kycId',
        loadComponent: () => import('./kyc/view-superagent-kyc/view-superagent-kyc.component').then(c => c.ViewSuperagentKycComponent)
      },
      {
        path: 'kyc/view-basic-customer',
        loadComponent: () => import('./kyc/kyc/view-basic-customer/view-basic-customer.component').then(c => c.ViewBasicCustomerComponent)
      },
      {
        path: 'kyc/view-customer-kyc/:kycId',
        loadComponent: () => import('./kyc/kyc/view-customer-kyc/view-customer-kyc.component').then(c => c.ViewCustomerKycComponent)
      },
      {
        path: 'kyc/agent',
        loadComponent: () => import('./kyc/kyc/agent-kyc/agent-kyc.component').then(c => c.AgentKycComponent)
      },
      {
        path: 'kyc/view-basic-agent',
        loadComponent: () => import('./kyc/kyc/view-basic-agent/view-basic-agent.component').then(c => c.ViewBasicAgentComponent)
      },
      {
        path: 'kyc/reset-password',
        loadComponent: () => import('./kyc/kyc/reset-password/reset-password.component').then(c => c.ResetPasswordComponent)
      },
      {
        path: 'kyc/view-agent-kyc/:kycId',
        loadComponent: () => import('./kyc/kyc/view-agent-kyc/view-agent-kyc.component').then(c => c.ViewAgentKycComponent)
      },
      
      {
        path: 'kyc/view-masteragent-kyc/:kycId',
        loadComponent: () => import('./kyc/kyc/view-masteragent-kyc/view-masteragent-kyc.component').then(c => c.ViewMasteragentKycComponent)
      },
      {
        path: 'kyc/toast',
        loadComponent: () => import('./kyc/kyc/toast/toast.component').then(c => c.ToastComponent)
      },
      {
        path: 'kyc/view-shareholder-details',
        loadComponent: () => import('./kyc/kyc/view-shareholders-details/view-shareholders-details.component').then(c => c.ViewShareholdersDetailsComponent)
      },
      
      {
        path: 'kyc/view-authorized-signatory-details/:kycId',
        loadComponent: () => import('./kyc/kyc/view-authorized-signatory-details/view-authorized-signatory-details.component').then(c => c.ViewAuthorizedSignatoryDetailsComponent)
      },
      { path: "Branch", loadComponent:()=> import('./role-management/role-management/role-management/branch-list/branch-list.component').then(c=>c.BranchListComponent)},
      { path: "Department", loadComponent:()=> import('./role-management/role-management/role-management/department-list/department-list.component').then(c=>c.DepartmentListComponent)},
      { path: "Role", loadComponent:()=> import('./role-management/role-management/role-management/roles-landing/roles-landing.component').then(c=>c.RolesLandingComponent)},
      {
        path: "Create-role",
        loadComponent: () =>
          import(
            "./role-management/role-management/role-management/create-role/create-role.component"
          ).then((c) => c.CreateRoleComponent),
      },
      {
        path: "ComponentList",
        loadComponent: () =>
          import(
            "./role-management/role-management/role-management/component-landing/component-landing.component"
          ).then((c) => c.ComponentLandingComponent),
      },
      {
        path: "SubComponentList",
        loadComponent: () =>
          import(
            "./role-management/role-management/role-management/sub-component-landing/sub-component-landing.component"
          ).then((c) => c.SubComponentLandingComponent),
      },
      {
        path: "editRole/:roleId",
        loadComponent: () =>
          import(
            "./role-management/role-management/role-management/edit-role/edit-role.component"
          ).then((c) => c.EditRoleComponent),
      },
      {
        path: 'listOfSubProducts',
        loadComponent: () => import('./product/sub-product/sub-product.component').then(c => c.SubProductComponent) 
      },
      {
        path: 'editListOfSubProducts',
        loadComponent: () => import('./product/sub-product/edit-subproduct-table/edit-subproduct-table.component').then(c => c.EditSubproductTableComponent) 
      },
      { path: 'account-maintanance', loadComponent: () => import('./transactions-ngl/transaction-gl/transaction-gl.component').then(c => c.TransactionGlComponent) },

      { path: 'ProductmasterComponent', loadComponent: () => import('./product/productmaster/productmaster.component').then(c => c.ProductmasterComponent) },
      { path: 'createProduct', loadComponent: () => import('./product/product/create-master-product/create-master-product.component').then(c => c.CreateMasterProductComponent) },
      { path: 'ServiceComponent', loadComponent: () => import('./product-configurations/service/service.component').then(c => c.ServiceComponent) },
      { path: 'SubProductComponent', loadComponent: () => import('./product/sub-product/list-subproduct-table/list-subproduct-table.component').then(c => c.ListSubproductTableComponent) },
      { path: 'TransactionLimitComponent', loadComponent: () => import('./product-configurations/transaction-limit/transaction-limit.component').then(c => c.TransactionLimitComponent) },
      { path: 'ServiceComponent', loadComponent: () => import('./product-configurations/service/service.component').then(c => c.ServiceComponent) },
      { path: 'SubProductComponent', loadComponent: () => import('./product/sub-product/list-subproduct-table/list-subproduct-table.component').then(c => c.ListSubproductTableComponent) },
      { path: 'TransactionLimitComponent', loadComponent: () => import('./product-configurations/transaction-limit/transaction-limit.component').then(c => c.TransactionLimitComponent) },
      { path: 'ChargesAndCommissionComponent', loadComponent: () => import('./product/charges-and-commision/charges-and-commision.component').then(c => c.ChargesAndCommisionComponent) },
      { path: 'ProductComponent', loadComponent: () => import('./product/product/product.component').then(c => c.ProductComponent) },
      { path: 'TaxComponent', loadComponent: () => import('./product-configurations/tax/tax.component').then(c => c.TaxComponent) },
      { path: 'FeesAndCommissionComponent', loadComponent: () => import('./product-configurations/fees-and-commission/fees-and-commission.component').then(c => c.FeesAndCommissionComponent) },
      { path: 'FeesAndCommissionSlabComponent', loadComponent: () => import('./product-configurations/fees-and-commission-slab/fees-and-commission-slab.component').then(c => c.FeesAndCommissionSlabComponent) },
      { path: 'AmountLimitComponent', loadComponent: () => import('./product-configurations/amount-limit/amount-limit.component').then(c => c.AmountLimitComponent) },
      { path: 'CashbackComponent', loadComponent: () => import('./product/cashback/cashback.component').then(c => c.CashbackComponent) },
      { path: 'AgencyFeesAndCommission', loadComponent: () => import('./agency-fees-and-commission/list-afc-details/list-afc-details.component').then(c => c.ListAFCDetailsComponent) },
      {
        path: 'AFCComponent',
        loadComponent: () => import('./agency-fees-and-commission/agency-fees-and-commission.component').then(c => c.AgencyFeesAndCommissionComponent) 
      },
      { path: 'BOLanding', loadComponent: () => import('./user-management/user-management/user-management/bo/bo.component').then(c => c.BOComponent) },
      { path: 'AgentLanding', loadComponent: () => import('./user-management/user-management/user-management/bo/agents/agents.component').then(c => c.AgentsComponent) },
      { path: 'CustomerLanding', loadComponent: () => import('./user-management/user-management/user-management/bo/customer/customer.component').then(c => c.CustomerComponent) },
      { path: 'SubAgentLanding', loadComponent: () => import('./user-management/user-management/user-management/bo/agents/sub-agent/sub-agent.component').then(c => c.SubAgentComponent) },
      { path: 'MerchantLanding', loadComponent: () => import('./user-management/user-management/user-management/bo/agents/merchant/merchant.component').then(c => c.MerchantComponent) },
      { path: 'SubMerchantLanding', loadComponent: () => import('./user-management/user-management/user-management/bo/agents/agents.component').then(c => c.AgentsComponent) },      
      { path: 'CorporateManagement', loadComponent: () => import('./user-management/user-management/user-management/corporate/corporate.component').then(c => c.CorporateComponent) },
      { path: 'CorporateFormComponent', loadComponent: () => import('./user-management/user-management/user-management/corporate-form/corporate-form.component').then(c => c.CorporateFormComponent) },
      { path: 'WalletManagement', loadComponent: () => import('./wallet-management/wallet-management/wallet-list/wallet-list.component').then(c => c.WalletListComponent) },
      { path: 'Systemwallets', loadComponent: () => import('./wallet-management/systemwallet/systemwallet.component').then(c => c.SystemwalletComponent) },
      { path: 'pushPullMoney', loadComponent: () => import('./wallet-management/push-pull-money/push-pull-money.component').then(c => c.PushPullMoneyComponent) },
      { path: 'viewcorporate', loadComponent: () => import('./user-management/user-management/user-management/corporate-user/view-copoarte-user/view-copoarte-user.component').then(c => c.ViewCopoarteUserComponent) },
      { path: 'registerCorporateUser', loadComponent: () => import('./user-management/user-management/user-management/corporate-user/register-copoarte-user/register-copoarte-user.component').then(c => c.RegisterCopoarteUserComponent) },

      
      { path: 'CreateSupplier', loadComponent: () => import('./supplier-management-module/supplier-master/create/create.component').then(c => c.CreateComponent) },
      { path: 'EditSupplier/:id', loadComponent: () => import('./supplier-management-module/supplier-master/create/create.component').then(c => c.CreateComponent) },
      { path: 'CreateTopup', loadComponent: () => import('./supplier-management-module/topup-credit-purchase/create/create.component').then(c => c.CreateComponent) },
      { path: 'SupplierList', loadComponent: () => import('./supplier-management-module/supplier-master/supplier-list/supplier-list.component').then(c => c.SupplierListComponent) },

       { path: 'SDNupload', loadComponent: () => import('./sdn-management/aml-upload-sdn/aml-upload-sdn.component').then(c => c.AmlUploadSdnComponent) },
       { path: 'create-risk-parameter', loadComponent: () => import('./sdn-management/create-risk-parameter/create-risk-parameter.component').then(c => c.CreateRiskParameterComponent) },
       { path: 'list-risk-parameter', loadComponent: () => import('./sdn-management/list-risk-parameter/list-risk-parameter.component').then(c => c.ListRiskParameterComponent) },
       
       { path: 'create-risk-tier', loadComponent: () => import('./sdn-management/create-risk-tier/create-risk-tier.component').then(c => c.CreateRiskTierComponent) },
       { path: 'list-risk-tier', loadComponent: () => import('./sdn-management/list-risk-tier/list-risk-tier.component').then(c => c.ListRiskTierComponent) },
      
      
       { path: 'bulkUpload', loadComponent: () => import('./bulk-upload-module/bulk-upload-module.component').then(c => c.BulkUploadModuleComponent) },
       { path: 'bulkCustomerUpload', loadComponent: () => import('./bulk-upload-module/customer-onboarding/bulk-customer-onboarding/bulk-customer-onboarding.component').then(c => c.BulkCustomerOnboardingComponent) },
      
       { path: 'BalanceSheet', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BatchReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BreshnaReconcilitationReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'TransactionSummaryReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'AgentLiquidityReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'AgentSummaryReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'CashInReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'CashOutReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'EMoneyTransferReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'LCTR', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'SalesTrendReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'StockBatchReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'ProfitabilityReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'ProfitabilityReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'CorporateSummaryReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'CustomerSummaryReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'SalesVolumeByChannel', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'SalesVolumeByAgents', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'ProfitAndLossReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BankReconcilitationReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BatchReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BankReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'AgentReconcilationReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'AccStatementReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'AirtimeReconciliationReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BeginningBalanceReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'BillPaymentReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'QuarterlyProfitAndLoss', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'MonthlyBalanceReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'MonthlyProfitAndLoss', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'FinancialConsolidatedReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'DirectBankingCashFlowReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },
       { path: 'KYCReport', loadComponent: () => import('./report-management/view-report-section/view-report-section.component').then(c => c.ViewReportSectionComponent) },

      { path: 'salaryprocess', loadComponent: () => import('./salary-processing/bulk-salary-upload/salary-dashboard/salary-dashboard.component').then(c => c.SalaryDashboardComponent) },
      // { path: 'EditSupplier', loadComponent: () => import('./supplier-management/supplier-master/edit/edit.component').then(c => c.EditComponent) },
      // { path: 'TopupCreditPurchase', loadComponent: () => import('./supplier-management/top-up-credit-purchase/create-topup/create-topup.component').then(c => c.CreateTopupComponent) },

    ];
    return routes

  }
  
}