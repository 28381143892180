<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Login</title>
    <link
      href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css"
      rel="stylesheet"
    />
    <style>
      .bg-custom-blue {
        background-color: rgb(0, 100, 162);
      }
      .bg-custom-orange {
        background-color: rgb(244, 122, 32);
      }
    </style>
  </head>
  <body class="flex items-center justify-center min-h-screen bg-custom-blue">
    <div
      class="flex flex-col md:flex-row bg-white rounded-lg shadow-lg mx-4 md:mx-auto overflow-hidden"
      style="max-width: 1000px; width: 1000px"
    >
      <!-- Left side with image -->
      <div
        class="md:w-1/2 bg-cover bg-center lt-md:hidden"
        style="
          background-image: url('../../../assets/images/MM_RK_Design_600x800PX.svg');
          height: 650px;
        "
      ></div>
      <!-- Right side with form -->
      <div
        class="w-full md:w-1/2 p-8 md:p-12 flex items-center justify-center"
        style="height: 600px"
      >
        <div class="w-full">
          <ng-container [ngSwitch]="userType">
            <!-- Login Form - UserType - Agent -->
            <div
              *ngSwitchCase="userTypeConst.agent"
              class="w-1/2 p-8 flex flex-col justify-center"
            >
              <div class="mb-8 text-center">
                <img
                  src="../../../assets/images/logo.png"
                  alt="Logo"
                  class="mx-auto mb-4 w-28 h-auto"
                />
                <h2 class="text-2xl font-bold">Login - Agent</h2>
              </div>
              <form #loginForm="ngForm">
                <div class="mb-4">
                  <label class=" ">Username</label>
                  <input
                    id="username"
                    name="username"
                    [(ngModel)]="username"
                    type="email"
                    autocomplete="username"
                    pattern=""
                    required
                    class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <div
                    *ngIf="username && username.length < 9"
                    class="text-red-500 text-sm mt-1"
                  >
                    Invalid
                  </div>
                  <div
                    *ngIf="username == null || username == ''"
                    class="text-red-500 text-sm mt-1"
                  >
                    Required
                  </div>
                </div>
                <div class="mb-4">
                  <label for="password" class="">User Type</label>
                  <!--             <input type="password"  [(ngModel)]="password" id="password" class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500" required>
        -->
                  <select
                    class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    required
                  >
                    <option>Super Agent</option>
                    <option>Merchant</option>
                    <option>Micro Merchant</option>
                    <option>USSD Staff</option>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="password" class="">Password</label>
                  <input
                    type="password"
                    name="password"
                    [(ngModel)]="password"
                    id="password"
                    class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    required
                  />
                </div>

                <div class="flex items-center justify-between mb-4">
                  <a
                    href="#"
                    class="text-[rgb(0,100,162)] font-semibold hover:underline"
                    >Forgot Password?</a
                  >
                </div>
                <button
                  type="submit"
                  class="w-full py-3 bg-custom-orange hover:bg-orange-600 text-white font-bold rounded-lg transition duration-200"
                  (click)="onSubmit()"
                >
                  Sign In
                </button>
              </form>
              <!-- <div class="text-center mt-4">
                <p>
                  Not a Member?
                  <a
                    class="text-[rgb(0,100,162)] font-semibold hover:underline"
                    (click)="invokeSwitchTo(contexts[2])"
                    >Create an Account</a
                  >
                </p>
              </div> -->
            </div>
            <!--  Login Form - UserType - Backoffice -->
            <div *ngSwitchCase="userTypeConst.backoffice">
              <div class="mb-8 text-center">
                <img
                  src="../../../assets/images/logo.png"
                  alt="Logo"
                  class="mx-auto mb-4 w-28 h-auto"
                />
                <div class="text-2xl font-bold text-[rgb(0,100,162)]">
                  Login - Backoffice
                </div>
              </div>
              <div *ngIf="changePassword == false && login">
                <form #loginForm="ngForm">
                  <div class="mb-4">
                    <label class=" ">Username</label>
                    <input
                      id="username"
                      name="username"
                      [(ngModel)]="username"
                      [pattern]="emailPattern"
                      type="email"
                      autocomplete="username"
                      pattern=""
                      required
                      #newPasswordCtrl="ngModel"
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    <div
                    *ngIf="username && !username.match(emailPattern)"
                    class="text-red-500 text-sm mt-1"
                  >
                    Invalid User Name
                  </div>
                    <div
                      *ngIf="username?.length == 0"
                      class="text-red-500 text-sm mt-1"
                    >
                      Username is required
                    </div>
                  </div>
                  <!-- <div class="mb-4">
                    <label for="password">Password bb</label>
                    <input
                    [maxlength]="12"
                    type="password"
                      name="password"
                      [(ngModel)]="password"
                      #newPassword="ngModel"
                      id="password"
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      required
                      pattern="(?=.*[A-Z])(?=.*[0-9]).{6,}"
                    />
                    <div
                      *ngIf="newPassword?.errors && (newPassword.dirty || newPassword.touched)"
                      class="text-red-500 text-sm mt-1"
                    >
                      <div *ngIf="password.length == 0">Password is required.</div>
                      <div *ngIf="newPassword.errors">
                        Password must contain at least one uppercase letter, 0-9 digits, and be at least 8 characters long.
                      </div>
                    </div>
                  </div> -->
                  <div class="mb-4 relative">
                    <label for="password">Password</label>
                    <input
                      [maxlength]="12"
                      [type]="showPassword ? 'text' : 'password'"
                      name="password"
                      [(ngModel)]="password"
                      #newPassword="ngModel"
                      id="password"
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      required
                      pattern="(?=.*[A-Z])(?=.*[0-9]).{6,}"
                    />
                    <span (click)="togglePasswordVisibility()" class="right-3 top-9 absolute">
                      <i [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                    <div
                      *ngIf="newPassword?.errors && (newPassword.dirty || newPassword.touched)"
                      class="text-red-500 text-sm mt-1"
                    >
                      <div *ngIf="password.length == 0">Password is required.</div>
                      <div *ngIf="newPassword.errors">
                        Password must contain at least one uppercase letter, 0-9 digits, and be at least 8 characters long.
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  <div
                    (click)="changePass(true)"
                    class="flex items-center justify-between mb-4"
                  >
                    <div
                      class="text-[rgb(0,100,162)] font-semibold hover:underline"
                    >
                      Forgot Password?
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="w-full py-3 bg-custom-orange
                     hover:bg-orange-600 text-white font-bold rounded-lg transition duration-200
                     disabled:cursor-not-allowed"
                    (click)="onSubmit()"
                  >
                    Sign In
                  </button>
                </form>
              </div>

              <div *ngIf="changePasswordotp">
                <div class="mb-4">
                  <label class=" ">Registered Email</label>
                  <input
                  #newEmailCtrl="ngModel"
                    id="username"
                    name="username"
                    [(ngModel)]="email"
                    type="email"
                    autocomplete="username"
                    pattern=""
                    required
                    class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <div
                      *ngIf="newEmailCtrl?.errors"
                      class="text-red-500 text-sm mt-1"
                    >
                      Email is required
                    </div>
                </div>

                <button
                [disabled]="!email"
                  (click)="sendOtp()"
                  type="submit"
                  class="w-full py-3 bg-custom-orange hover:bg-orange-600 text-white font-bold rounded-lg transition duration-200"
                >
                  Request OTP
                </button>
              </div>

              <div *ngIf="otpVerify">
                <div class="mb-4">
                  <label class=" ">Enter OTP</label>
                  <input
                  #newOtpCtrl="ngModel"
                    [(ngModel)]="otp"
                    type="email"
                    required
                    class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <div
                  *ngIf="newOtpCtrl?.errors"
                  class="text-red-500 text-sm mt-1"
                >
                  OTP is required
                </div>
                </div>

                <button
                [disabled]="!otp"
                  (click)="onSaveOtp()"
                  type="submit"
                  class="w-full py-3 bg-custom-orange hover:bg-orange-600 text-white font-bold rounded-lg transition duration-200"
                >
                  Verify OTP
                </button>
              </div>

              <div *ngIf="changePasswordNew">
                <form #passwordForm="ngForm" (ngSubmit)="onSubmit()">
                  <div class="mb-4">
                    <label>New Password</label>
                    <input
                      [(ngModel)]="newpasswordfield"
                      name="newpassword"
                      type="password"
                      required
                      pattern="(?=.*[A-Z])(?=.*[0-9]).{6,}"
                      [maxLength]="12"
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      #newPassword="ngModel"
                    />
                    <!-- Error message for required and minlength validation -->
                    <div
                    *ngIf="newPassword?.errors && (newPassword.dirty || newPassword.touched)"
                    class="text-red-500 text-sm mt-1"
                  >
                    <div *ngIf="newpasswordfield.length == 0">Password is required.</div>
                    <div *ngIf="newPassword.errors">
                      Password must contain at least one uppercase letter, 0-9 digits, and be at least 8 characters long.
                    </div>
                  </div>
                  </div>

                  <div class="mb-4">
                    <label>Confirm Password</label>
                    <input
                      [(ngModel)]="confirmpassword"
                      name="confirmpassword"
                      type="password"
                      required
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      #confirmPassword="ngModel"
                    />
                    <div
                      *ngIf="confirmPassword.invalid && confirmPassword.touched"
                      class="text-red-500 text-sm"
                    >
                      <div *ngIf="confirmPassword.errors">
                        Confirm password is required.
                      </div>
                    </div>
                    <div
                      *ngIf="
                        confirmpassword && confirmpassword !== newpasswordfield
                      "
                      class="text-red-500 text-sm"
                    >
                      Passwords do not match.
                    </div>
                  </div>
                </form>

                <button
                  (click)="pwdReset()"
                  type="submit"
                  class="w-full py-3 bg-custom-orange hover:bg-orange-600 text-white font-bold rounded-lg transition duration-200"
                >
                  Change Password
                </button>
              </div>

              <div *ngIf="changePassword">
                <form #loginForm="ngForm">
                  <div class="mb-4">
                    <label class=" ">Old Password</label>
                    <input
                      id="username"
                      name="username"
                      [(ngModel)]="oldpassword"
                      type="password"
                      pattern=""
                      required
                      pattern="(?=.*[A-Z])(?=.*[0-9]).{6,}"
                      [maxLength]="12"
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      #oldPassword="ngModel"
                    />

                        <div
                    *ngIf="oldPassword?.errors && (oldPassword.dirty || oldPassword.touched)"
                    class="text-red-500 text-sm mt-1"
                  >
                    <div *ngIf="oldpassword.length == 0">Old Password is required.</div>
                    <div *ngIf="oldPassword.errors">
                      Password must contain at least one uppercase letter, 0-9 digits, and be at least 8 characters long.
                    </div>
                  </div>
                  </div>
                  <div class="mb-4">
                    <label for="password" class="">New Password</label>
                    <input
                      type="password"
                      name="password"
                      [(ngModel)]="newpassword"
                      pattern="(?=.*[A-Z])(?=.*[0-9]).{6,}"
                      [maxLength]="12"
                      id="password"
                      class="w-full mt-1 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      required
                      #newSetPassword="ngModel"

                    />
                    <div
                    *ngIf="newSetPassword?.errors && (newSetPassword.dirty || newSetPassword.touched)"
                    class="text-red-500 text-sm mt-1"
                  >
                    <div *ngIf="newpassword.length == 0">New Password is required.</div>
                    <div *ngIf="newSetPassword.errors">
                      Password must contain at least one uppercase letter, 0-9 digits, and be at least 8 characters long.
                    </div>
                  </div>
                  </div>
                  <button
                    type="submit"
                    class="w-full py-3 bg-custom-orange hover:bg-orange-600 text-white font-bold rounded-lg transition duration-200"
                    (click)="changePasswordApi()"
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </div>
            <!-- Login Form - UserType - Customer -->
          </ng-container>
        </div>
      </div>
    </div>
  </body>
</html>

<app-loader *ngIf="isLoading"></app-loader>
